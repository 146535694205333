@import "../../styles/common.scss";
.token-attributes {
	padding: 0.8rem 0;
	text-transform: uppercase;
	color: $text-white-t;
	font-weight: 800;
	font-family: "nunito";
	font-size: 0.8rem;
	display: flex;
	flex-wrap: wrap;
	> span {
		margin: 0.25rem 0.8rem 0 0;
	}
	.common {
		color: $common;
	}
	.rare {
		color: $rare;
	}
	.epic {
		color: $epic;
	}
	.mythical {
		color: $mythical;
	}
}
