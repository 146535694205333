@import "../../styles/common.scss";
.nav-buttons {
	display: flex;
	justify-content: center;
	.button {
		margin: 2.5rem 1rem;
		&.secondary {
			border-color: $brand-yellow;
			color: $brand-yellow-d;
		}
	}
}
