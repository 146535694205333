@import "./styles/common.scss";
.App {
	max-width: 1920px;
	margin: 0 auto;
	// display: flex;
	min-height: 100vh;
	height: 100%;
	background: url("./assets/images/galaxy.jpg") center center fixed;
	background-position: 0% 40%;
	background-size: cover;

	@include respond(phone) {
		display: block;
	}
}
