@import "./mixins";
@import "./variables";

body,
button,
div,
p,
span,
input,
option,
h1,
h2,
h3,
h4,
h5,
h6,
select {
	font-family: "Rubik";
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: normal;
	margin: 0;
}

/* custom scrollbar throughout the app */
* {
	scrollbar-width: thin;
	scrollbar-color: $body-bg-1 $body-bg-2;
	scrollbar-track-color: transparent;

	&::-webkit-scrollbar {
		width: 10px;
		height: 10px;
		background-color: $body-bg-1;
		border: 2px solid $body-bg-2;
	}

	&::-webkit-scrollbar-corner {
		background-color: $body-bg-1;
	}

	&::-webkit-scrollbar-track {
		background-color: transparent;
	}

	&::-webkit-scrollbar-thumb {
		-webkit-border-radius: 5px;
		border-radius: 5px;
		background-color: $body-bg-1;
		border: 2px solid $body-bg-2;
	}
}

// UTILITY CLASSES

.row {
	display: flex;
	flex-direction: row;
}

input {
	&:focus-visible {
		outline: 2px solid $brand-purple-d;
	}
	font-family: "Nunito";
	color: $text-white;
	text-align: center;
	background-color: transparent;
	border: 1px solid $brand-purple;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
