// @import "../../styles/_mixins.scss";
// @import "../../styles/_variables.scss";
@import "../../styles/common.scss";
.button {
	cursor: pointer;
	border: none;
	outline: none;
	transition: filter 100ms linear;
	border-radius: 99999px;
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: 0;
	white-space: nowrap;

	// sizes
	&.normal {
		padding: 0.75rem 1.75rem;
		font-weight: 600;

		@include respond(phone) {
			padding: 0.5rem 1.5rem;
		}
	}

	&.compact {
		padding: 0.5rem 1.5rem;
		font-weight: 600;

		@include respond(phone) {
			font-size: small;
			padding: 0.3rem 0.8rem;
		}
	}

	&.mini {
		padding: 0.25rem 0.75rem;
		font-size: small;
	}

	// disabled state
	&:disabled {
		color: $grey !important;
		cursor: default;
		filter: none !important;

		&:not(.text) {
			background: rgba(255, 255, 255, 0.4) !important;
		}
	}

	&.box {
		border-radius: 0.2em;
	}

	&:not(.text) {
		// color
		&.primary {
			background: linear-gradient($brand-yellow, $brand-yellow-d);
			color: $text-black;

			&:hover {
				filter: brightness(1.08);
			}

			&:active {
				transition: filter 0ms linear;
				background: $brand-yellow;
			}
		}

		&.secondary {
			background-color: transparent;
			color: $text-white;
			transition: background-color 100ms linear;
			backdrop-filter: $glass-blur;
			border: 2px solid $brand-purple;

			&::before {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				border-radius: 99999px;
				padding: 2.5px;

				// ! wasn't working in safari so using border instead
				// background: linear-gradient($brand-purple, $brand-purple-d);
				// mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
				// -webkit-mask: linear-gradient(#fff 0 0) content-box,
				//   linear-gradient(#fff 0 0);
				// -webkit-mask-composite: destination-out;
				// mask-composite: exclude;
			}

			&:hover {
				background-color: rgba($brand-purple, 0.2);
			}

			&:active {
				transition: filter 0ms linear;
				background: linear-gradient($brand-purple, $brand-purple-d);
			}
		}

		&.danger {
			background-color: transparent;
			color: $text-white;
			transition: background-color 100ms linear;
			backdrop-filter: $glass-blur;

			&::before {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				border-radius: 99999px;
				padding: 2px;
				background: linear-gradient($red, $error-red);
				mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
				-webkit-mask: linear-gradient(#fff 0 0) content-box,
					linear-gradient(#fff 0 0);
				-webkit-mask-composite: destination-out;
				mask-composite: exclude;
			}

			&:hover {
				background-color: rgba($brand-purple, 0.2);
			}

			&:active {
				transition: filter 0ms linear;
				background: linear-gradient($brand-purple, $brand-purple-d);
			}
		}
	}

	&.text {
		background-color: transparent;
		color: $text-white;
		font-weight: 800;
		transition: color 100ms linear;
		padding: 0;

		&.primary:hover,
		&.primary.active {
			color: $brand-yellow;
		}

		&.secondary:hover,
		&.secondary.active {
			color: $brand-purple;
		}
	}

	&.loading {
		cursor: wait !important;
		filter: brightness(0.8);
	}

	> span:not(:last-child) {
		margin-right: 0.5rem;
	}

	> span {
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
