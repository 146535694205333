@import "../../styles/common.scss";

.tokens {
	h2 {
		margin: 2rem;
		text-align: center;
	}
	.tokens-grid {
		display: grid;
		place-items: center;
		align-items: stretch;
		gap: 1em;
		padding: 1em;
		grid-template-columns: repeat(auto-fill, minmax(16em, 1fr));

		@include respond(phone) {
			font-size: 10px;
		}
	}
}
