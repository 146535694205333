@import "../../styles/common.scss";
.header {
	justify-content: space-between;
	padding: 0.5rem;
	border-bottom: 1px solid rgba($text-white, 0.2);
	border: 0 0 1px 0 solid red;
	width: 100%;
	max-height: 5rem;

	img {
		max-height: 3.5rem;
		width: auto;
		height: auto;
	}
	.right {
		> a {
			color: $text-white;
		}
		.sync-wallet-button {
			display: inline;
			margin: 0.7rem 2.5rem;
		}
	}
}
