@import "../../styles/common.scss";
.resource {
	text-align: center;
	margin-top: 4rem;
	> h2 {
		font-size: 2rem;
		font-weight: 400;
	}
	.resource-table {
		display: grid;
		grid-template-columns: auto auto;
		@include respond(phone) {
			grid-template-columns: auto;
		}
		justify-content: center;
		margin-top: 4rem;
		.resource-element {
			display: flex;
			padding: 2rem;
			> img {
				max-width: 5rem;
			}
			.value {
				text-align: start;
				display: flex;
				flex-direction: column;
				justify-content: space-evenly;
				margin-left: 1rem;
				> label {
					font-family: "Nunito";
					font-weight: 600;
					font-size: 1.1rem;
				}
				> input {
					@include respond(normal-desktop) {
						width: 12rem;
					}
					height: 2rem;
				}
			}
		}
	}
}
