@mixin respond($breakpoint) {
	@if $breakpoint == phone-small {
		@media only screen and (max-width: 20em) {
			@content;
		}
	}
	@if $breakpoint == phone {
		@media only screen and (max-width: 40em) {
			@content;
		} //600px
	}
	@if $breakpoint == tab-port {
		@media only screen and (max-width: 56.25em) {
			@content;
		} //900px
	}
	@if $breakpoint == tab-land {
		@media only screen and (max-width: 75em) {
			@content;
		} //1200px
	}
	@if $breakpoint == normal-desktop {
		@media only screen and (min-width: 85.375em) {
			@content;
		} //1800px
	}
	@if $breakpoint == big-desktop {
		@media only screen and (min-width: 112.5em) {
			@content;
		} //1800px
	}
}
