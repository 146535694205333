$body-bg: #050311;
$body-bg-1: #c4c4c433;
$body-bg-2: #32314a;

// TEXT
$text-white: #ffffff;
$text-white-t: #ffffff99;
$text-black: #1a1919;

// PRIMARY
$brand-yellow: #ffd354;
$brand-yellow-d: #edb72d;

$brand-purple-l: #7879f1;
$brand-purple: #9f69e3;
$brand-purple-d: #6e22cd;

$brand-orange: #e36969;
$brand-orange-d: #cd5522;

$error-red: #f70000;

// SECONDARY
$sec-dark-dark: #321952;
$sec-purple-light: #9f69e3;

// OTHERS
$green: #5eb9a2;
$grey: #878787;
$orange: #f09035;
$blue: #2557cb;
$blue-green: #0092c2;
$red: #ab3428;
$purple-dark: #321952;
$dark-grey: #303549;
$dark-pink: #bf51c1;
$magenta-dark: #2c0237;

// Blur values
$glass-blur: blur(4px);
$glass-blur-2: blur(12px);
$glass-color: rgba($text-white, 5%);

// rarity colors
$common: #0039ff;
$rare: #ff7e00;
$epic: #a800ff;
$mythical: #c61e1e;
