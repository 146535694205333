@import "../../styles/common.scss";

.token-card {
	padding: 0.2em;
	backdrop-filter: $glass-blur;
	border-radius: 12px;
	border: 1px solid $brand-purple;
	padding: 0.8em;
	display: flex;
	flex-direction: column;
	color: #fff;
	width: clamp(16em, 100%, 20em);
	height: 100%;
	background-color: $glass-color;
	backdrop-filter: $glass-blur;
	scroll-snap-align: start;
	text-decoration: none;

	> .pic {
		border-radius: 6px;
		height: 12rem;
		background: url("../../assets/images/img-bg.png") no-repeat center center;
		display: flex;
		justify-content: center;
		align-items: center;
		overflow: hidden;
		margin-bottom: 1em;

		&.pic-tezotop img {
			transform: scale(1.1);
		}

		@include respond(phone) {
			height: 9rem;
		}
	}

	> .body {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		gap: 0.5rem;

		> .title {
			font-size: 1.2em;
		}
	}
}
