.edition {
	.button {
		box-sizing: content-box;
		margin: 0.5rem;
		padding: 0;
		width: 1.7rem;
		height: 1.7rem;
	}
	> input {
		width: 4ch;
	}
}
